var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"portfolio-page",attrs:{"fluid":""}},[_c('v-row',{staticClass:"d-flex justify-space-between mt-10 mb-1",attrs:{"no-gutters":""}},[_c('h1',{staticClass:"page-title mb-1"},[_vm._v("Portfolio")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize button-shadow mr-1",attrs:{"color":"secondary"},on:{"click":_vm.addPortfolio}},'v-btn',attrs,false),on),[_vm._v("Add")])]}}])})],1),_c('v-row',[_c('v-col',{attrs:{"lg":"12"}},[_c('v-card',{staticClass:"mx-1 mb-1"},[_c('v-card-title',{staticClass:"pa-6 pb-3"},[_c('p',[_vm._v("Headings")])]),_c('v-card-text',{staticClass:"pa-6 pt-0"},[_c('v-row',{staticClass:"portfolio-widget pb-6",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"card-dark-grey",attrs:{"cols":"12"}},[_c('v-container',{attrs:{"fluid":"","grid-list-md":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('div',{staticClass:"demo",attrs:{"id":"list-complete-demo"}},[_c('v-btn',{staticClass:"mx-2",attrs:{"retain-focus-on-click":"","small":""},on:{"click":function($event){return _vm.filter('all')}}},[_vm._v("All")]),_vm._l((_vm.categories),function(category){return _c('v-btn',{key:category.id,staticClass:"mx-2",attrs:{"retain-focus-on-click":"","small":""},on:{"click":function($event){return _vm.filter(category.id)}}},[_vm._v(_vm._s(category.name))])}),_c('transition-group',{staticClass:"list-complete",attrs:{"name":"list-complete","tag":"section"}},_vm._l((_vm.filteredItems),function(item){return _c('div',{key:item.id,staticClass:"list-complete-item"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-img',{staticClass:"list-complete-img",attrs:{"src":_vm.url + item.image,"alt":""}},[_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out orange darken-2 v-card--reveal display-3 white--text",staticStyle:{"height":"100%"}},[_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":function($event){$event.preventDefault();_vm.deleteDialog = true;
                                    _vm.toDelete = item.id;}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1):_vm._e()])],1)]}}],null,true)})],1)}),0)],2)])],1),_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"warning headline",staticStyle:{"font-weight":"bold","color":"white"}},[_c('v-icon',{staticStyle:{"color":"white"}},[_vm._v(" mdi-alert ")]),_vm._v(" Confirm delete ")],1),_c('v-card-text',[_c('center',[_vm._v(" Are you sure you want to delete? This action can not be undone ")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":"","disabled":_vm.deleteLoading},on:{"click":function($event){_vm.deleteDialog = false;
                        _vm.toDelete = '';}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"orange darken-1","loading":_vm.deleteLoading,"text":""},on:{"click":function($event){return _vm.deletePortfolio(_vm.toDelete)}}},[_vm._v(" Yes ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }