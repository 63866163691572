<template>
  <v-container fluid class="portfolio-page">
    <v-row no-gutters class="d-flex justify-space-between mt-10 mb-1">
      <h1 class="page-title mb-1">Add Portfolio</h1></v-row
    >
    <v-row>
      <v-col lg="12">
        <v-card class="mx-1 mb-1">
          <v-card-title class="pa-6 pb-3">
            <p>Details</p>
          </v-card-title>
          <v-card-text class="pa-6 pt-0">
            <v-row no-gutters class="portfolio-widget pb-6">
              <v-col cols="12" class="card-dark-grey">
                <v-form @submit.prevent="save()">
                  <v-col cols="8">
                    <v-text-field
                      v-model="form.name"
                      label="Name"
                      hint="e.g Retoria, Decima"
                      required
                    ></v-text-field>
                    <v-select
                      @change="onCategoryChange"
                      return-object
                      :items="categories"
                      item-text="name"
                      item-value="id"
                      label="Category"
                      required
                    ></v-select>
                    <v-file-input
                      v-model="form.image"
                      show-size
                      :rules="rules"
                      accept="image/png, image/jpeg, image/bmp"
                      placeholder="Pick an image"
                      prepend-icon="mdi-camera"
                      label="Image"
                      required
                    ></v-file-input>
                    <v-text-field
                      v-show="showUrlField"
                      v-model="form.url"
                      label="Url"
                      :required="showUrlField"
                    ></v-text-field>
                  </v-col>
                  <v-btn
                    type="submit"
                    class="ma-2"
                    :loading="isLoading"
                    :disabled="form.busy"
                    color="secondary"
                  >
                    Save
                  </v-btn>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import Form from "vform";
import { objectToFormData } from "object-to-formdata";
export default {
  name: "Portfolio",
  data() {
    return {
      isLoading: false,
      isCategoriesLoading: false,
      categories: [],
      showUrlField: false,
      form: new Form({
        name: "",
        category_id: "",
        image: [],
        url: "",
      }),
      rules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Image size should be less than 2 MB!",
      ],
    };
  },
  methods: {
    getCategories() {
      axios({
        method: "get",
        url: "portfolio-categories",
      })
        .then((response) => {
          this.categories = response.data;
          this.isCategoriesLoading = false;
        })
        .catch(() => {
          this.isCategoriesLoading = false;
        });
    },
    save() {
      this.isLoading = true;
      this.form
        .submit("post", "portfolios", {
          // Transform form data to FormData
          transformRequest: [
            // eslint-disable-next-line no-unused-vars
            function(data, headers) {
              return objectToFormData(data);
            },
          ],
        })
        .then(() => {
          this.isLoading = false;
          this.$router.push({ name: "portfolio" });
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    onCategoryChange(category) {
      this.form.category_id = category.id;
      if (category.type == 2 || category.type == 3) {
        this.showUrlField = true;
      }else {
        this.showUrlField = false;
      }
    },
  },
  mounted() {
    this.getCategories();
  },
};
</script>

<style src="./AddPortfolio.scss" scoped lang="scss"></style>
