var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"d-flex justify-space-between mt-10 mb-1",attrs:{"no-gutters":""}},[_c('h1',{staticClass:"page-title mb-1"},[_vm._v("Portfolio Categories")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize button-shadow mr-1",attrs:{"color":"secondary"},on:{"click":function($event){_vm.showAddCategoryForm = true}}},'v-btn',attrs,false),on),[_vm._v("Add")])]}}])})],1),_c('div',{staticClass:"tables-basic"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"employee-list mb-1"},[_c('v-card-title',{staticClass:"pa-6 pb-3"},[_c('p',[_vm._v("Categories")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.categories_table.search),callback:function ($$v) {_vm.$set(_vm.categories_table, "search", $$v)},expression:"categories_table.search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.categories_table.headers,"items":_vm.categories_table.categories,"search":_vm.categories_table.search,"item-key":"name"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(category){return _c('tr',{key:category.name},[_c('td',[_vm._v(_vm._s(category.name))]),_c('td',[_vm._v(_vm._s(_vm._f("formatType")(category.type)))]),_c('td',[_c('v-icon',{on:{"click":function($event){_vm.showDeleteDialog = true;
                        _vm.toDelete = category.id;}}},[_vm._v(" mdi-delete ")])],1)])}),0)]}}])})],1)],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.showAddCategoryForm),callback:function ($$v) {_vm.showAddCategoryForm=$$v},expression:"showAddCategoryForm"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Add Category")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"label":"Name","required":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-select',{attrs:{"items":_vm.types,"item-text":"name","item-value":"id","label":"Type","required":""},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.showAddCategoryForm = false}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"blue darken-1","loading":_vm.form.busy,"text":""},on:{"click":_vm.addPortfolioCategory}},[_vm._v(" Save ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.showDeleteDialog),callback:function ($$v) {_vm.showDeleteDialog=$$v},expression:"showDeleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Are you sure you want to delete this item?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.showDeleteDialog = false;
              _vm.toDelete = '';}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","loading":_vm.deleteLoading,"text":""},on:{"click":function($event){return _vm.deletePortfolioCategory()}}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }