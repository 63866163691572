import Vue from 'vue'
import App from './App.vue'
import router from './Routes'
import axios from "axios";
import store from './store/index'
import vuetify from './plugins/vuetify'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

Vue.use(Toast);

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.common["x-access-token"] = localStorage.token;
// axios.defaults.baseURL = window.location.origin + '/backend/public/api'
if (process.env.NODE_ENV === "production") {
  axios.defaults.baseURL = "https://skilzgraphics.com/api/";
} else {
  axios.defaults.baseURL =
    "http://localhost/projects/skilzgraphics-ltd/public/api/";
}

Vue.prototype.$http = axios;

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  render: h => h(App), store
}).$mount('#app')
