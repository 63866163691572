var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"dashboard-page"},[_c('v-row',{staticClass:"d-flex justify-space-between mt-10 mb-6",attrs:{"no-gutters":""}},[_c('h1',{staticClass:"page-title"},[_vm._v("Dashboard")])]),_c('v-row',[_c('v-col',{attrs:{"lg":"3","sm":"6","md":"5","cols":"12"}},[_c('v-card',{staticClass:"mx-1 mb-1"},[_c('v-card-title',{staticClass:"pa-6 pb-3"},[_c('p',[_vm._v("Visits Today")]),_c('v-spacer'),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"textColor"}},[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',_vm._l((_vm.mock.menu),function(item,i){return _c('v-list-item',{key:i,on:{"click":function () {}}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1)],1),_c('v-card-text',{staticClass:"pa-6 pt-0"},[_c('v-row',{staticClass:"pb-5",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"my-auto",attrs:{"cols":"5"}},[_c('span',{staticClass:"font-weight-medium card-dark-grey",staticStyle:{"font-size":"24px"}},[_vm._v("12, 678")])]),_c('v-col',{attrs:{"cols":"6"}},[_c('Trend',{attrs:{"data":_vm.getRandomDataForTrends(),"gradient":_vm.mock.trend.gradient,"height":40,"stroke-width":"4","smooth":""}})],1)],1),_c('v-row',{staticClass:"justify-space-between pb-3",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"5"}},[_c('div',{staticClass:"card-light-grey"},[_vm._v("Registrations")]),_c('div',{staticClass:"text-h6 card-dark-grey font-weight-regular"},[_vm._v(" 860 ")])]),_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"card-light-grey"},[_vm._v("Sign Out")]),_c('div',{staticClass:"text-h6 card-dark-grey font-weight-regular"},[_vm._v(" 32 ")])]),_c('v-col',{attrs:{"cols":"4","xl":"2"}},[_c('div',{staticClass:"text-right card-light-grey"},[_vm._v("Rate")]),_c('div',{staticClass:"text-right text-h6 card-dark-grey font-weight-regular"},[_vm._v(" 3.25% ")])])],1)],1)],1)],1),_c('v-col',{attrs:{"lg":"3","sm":"6","md":"7","cols":"12"}},[_c('v-card',{staticClass:"mx-1 mb-1"},[_c('v-card-title',{staticClass:"pa-6 pb-3"},[_c('p',[_vm._v("App Performance")]),_c('v-spacer'),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"textColor"}},[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',_vm._l((_vm.mock.menu),function(item,i){return _c('v-list-item',{key:i,on:{"click":function () {}}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1)],1),_c('v-card-text',{staticClass:"pa-6 pt-0"},[_c('v-row',{staticClass:"pb-5",attrs:{"no-gutters":""}},[_c('div',{staticClass:"mr-4"},[_c('v-icon',{staticClass:"ml-n2",attrs:{"color":"primary"}},[_vm._v(" mdi-circle-medium ")]),_c('span',{staticClass:"card-light-grey"},[_vm._v("Integration")])],1),_c('div',[_c('v-icon',{attrs:{"color":"warning"}},[_vm._v(" mdi-circle-medium ")]),_c('span',{staticClass:"card-light-grey"},[_vm._v("SDK")])],1)]),_c('v-row',{staticClass:"pb-3",attrs:{"no-gutters":""}},[_c('v-col',[_c('div',{staticClass:"text-h6 card-light-grey font-weight-regular"},[_vm._v(" Integration ")]),_c('v-progress-linear',{attrs:{"value":_vm.value,"background-color":"#ececec","color":"primary"}})],1)],1),_c('v-row',{staticClass:"pb-1",attrs:{"no-gutters":""}},[_c('v-col',[_c('div',{staticClass:"text-h6 card-light-grey font-weight-regular"},[_vm._v(" SDK ")]),_c('v-progress-linear',{attrs:{"value":_vm.value2,"background-color":"#ececec","color":"warning"}})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"lg":"3","sm":"6","md":"7","cols":"12"}},[_c('v-card',{staticClass:"mx-1 mb-1",staticStyle:{"min-height":"228px"}},[_c('v-card-title',{staticClass:"pa-6 pb-3"},[_c('p',[_vm._v("Server Overview")]),_c('v-spacer'),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"textColor"}},[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',_vm._l((_vm.mock.menu),function(item,i){return _c('v-list-item',{key:i,on:{"click":function () {}}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1)],1),_c('v-card-text',{staticClass:"pa-6 pt-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"my-auto text-truncate",attrs:{"cols":"6","md":"5","lg":"6","xl":"4"}},[_c('span',[_vm._v("60% / 37°С / 3.3 Ghz")])]),_c('v-col',{attrs:{"cols":"6","md":"7","lg":"6","xl":"8"}},[(_vm.apexLoading)?_c('ApexChart',{attrs:{"height":"35","type":"area","options":_vm.mock.apexArea1.options,"series":_vm.mock.apexArea1.series}}):_vm._e()],1)],1),_c('v-row',{staticClass:"my-3",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"my-auto text-truncate",attrs:{"cols":"6","md":"5","lg":"6","xl":"4"}},[_c('span',[_vm._v("54% / 31°С / 3.3 Ghz")])]),_c('v-col',{attrs:{"cols":"6","md":"7","lg":"6","xl":"8"}},[(_vm.apexLoading)?_c('ApexChart',{attrs:{"height":"35","type":"area","options":_vm.mock.apexArea2.options,"series":_vm.mock.apexArea2.series}}):_vm._e()],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"my-auto text-truncate",attrs:{"cols":"6","md":"5","lg":"6","xl":"4"}},[_c('span',[_vm._v("57% / 21°С / 3.3 Ghz")])]),_c('v-col',{attrs:{"cols":"6","md":"7","lg":"6","xl":"8"}},[(_vm.apexLoading)?_c('ApexChart',{attrs:{"height":"35","type":"area","options":_vm.mock.apexArea3.options,"series":_vm.mock.apexArea3.series}}):_vm._e()],1)],1)],1)],1)],1),_c('v-col',{attrs:{"lg":"3","sm":"6","md":"5","cols":"12"}},[_c('v-card',{staticClass:"mx-1 mb-1",staticStyle:{"height":"228px"}},[_c('v-card-title',{staticClass:"flex-nowrap pa-6 pb-3"},[_c('p',{staticClass:"text-truncate"},[_vm._v("Revenue Breakdown")]),_c('v-spacer'),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"textColor"}},[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',_vm._l((_vm.mock.menu),function(item,i){return _c('v-list-item',{key:i,on:{"click":function () {}}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1)],1),_c('v-card-text',{staticClass:"pa-6 pt-0 mb-1"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('ApexChart',{staticClass:"mt-1",attrs:{"height":"124","type":"donut","options":_vm.mock.apexPie.options,"series":_vm.generatePieSeries()}})],1)],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }