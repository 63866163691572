<template>
  <v-container fluid class="portfolio-page">
    <v-row no-gutters class="d-flex justify-space-between mt-10 mb-1">
      <h1 class="page-title mb-1">Portfolio</h1>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="addPortfolio"
            color="secondary"
            class="text-capitalize button-shadow mr-1"
            >Add</v-btn
          >
        </template>
      </v-menu></v-row
    >
    <v-row>
      <v-col lg="12">
        <v-card class="mx-1 mb-1">
          <v-card-title class="pa-6 pb-3">
            <p>Headings</p>
          </v-card-title>
          <v-card-text class="pa-6 pt-0">
            <v-row no-gutters class="portfolio-widget pb-6">
              <v-col cols="12" class="card-dark-grey">
                <v-container fluid grid-list-md>
                  <v-layout row wrap>
                    <div id="list-complete-demo" class="demo">
                      <v-btn
                        retain-focus-on-click
                        small
                        class="mx-2"
                        v-on:click="filter('all')"
                        >All</v-btn
                      >
                      <v-btn
                        v-for="category in categories"
                        :key="category.id"
                        retain-focus-on-click
                        small
                        class="mx-2"
                        v-on:click="filter(category.id)"
                        >{{ category.name }}</v-btn
                      >
                      <transition-group
                        name="list-complete"
                        class="list-complete"
                        tag="section"
                      >
                        <div
                          v-for="item in filteredItems"
                          v-bind:key="item.id"
                          class="list-complete-item"
                        >
                          <v-hover v-slot:default="{ hover }">
                            <v-img
                              class="list-complete-img"
                              :src="url + item.image"
                              alt=""
                            >
                              <v-expand-transition>
                                <div
                                  v-if="hover"
                                  class="d-flex transition-fast-in-fast-out orange darken-2 v-card--reveal display-3 white--text"
                                  style="height: 100%;"
                                >
                                  <v-btn
                                    color="primary"
                                    depressed
                                    @click.prevent="
                                      deleteDialog = true;
                                      toDelete = item.id;
                                    "
                                  >
                                    <v-icon>mdi-delete</v-icon>
                                  </v-btn>
                                </div>
                              </v-expand-transition>
                            </v-img>
                          </v-hover>
                        </div>
                      </transition-group>
                    </div>
                  </v-layout>
                </v-container>
                <v-dialog v-model="deleteDialog" max-width="290">
                  <v-card>
                    <v-card-title
                      class="warning headline"
                      style="font-weight: bold; color: white;"
                    >
                      <v-icon style="color: white">
                        mdi-alert
                      </v-icon>
                      Confirm delete
                    </v-card-title>
                    <v-card-text>
                      <center>
                        Are you sure you want to delete? This action can not be
                        undone
                      </center>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn
                        color="green darken-1"
                        text
                        @click="
                          deleteDialog = false;
                          toDelete = '';
                        "
                        :disabled="deleteLoading"
                      >
                        Cancel
                      </v-btn>

                      <v-btn
                        color="orange darken-1"
                        :loading="deleteLoading"
                        text
                        @click="deletePortfolio(toDelete)"
                      >
                        Yes
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

var url;
if (process.env.NODE_ENV === "production") {
  url = "https://skilzgraphics.com/storage/";
} else {
  url = "http://localhost/projects/skilzgraphics-ltd/public/storage/";
}
export default {
  name: "Portfolio",
  data: () => ({
    categories: [],
    items: [],
    currentTag: "all",
    url: url,
    deleteDialog: false,
    deleteLoading: false,
    toDelete: "",
  }),
  methods: {
    addPortfolio() {
      this.$router.push("/add_portfolio");
    },
    filter: function(tag) {
      this.currentTag = tag;
    },
    getCategories() {
      axios.get("portfolio-categories").then(({ data }) => {
        this.categories = data;
      });
    },
    getImages() {
      axios.get("portfolios").then(({ data }) => {
        this.items = [];
        if (data) {
          data.forEach(this.imagesToArray);
        }
      });
    },
    imagesToArray(value) {
      var item = {
        id: value.id,
        image: value.image,
        tags: ["all", value.portfolio_category_id],
      };
      this.items.push(item);
    },
    deletePortfolio(id) {
      this.deleteLoading = true;
      axios
        .delete("portfolios/" + id)
        .then(() => {
          this.getImages();
          this.deleteDialog = false;
          this.deleteLoading = false;
        })
        .catch(() => {
          this.deleteDialog = true;
          this.deleteLoading = false;
        });
    },
  },
  computed: {
    filteredItems: function() {
      var filter = this.currentTag;
      return this.items.filter(function(item) {
        return item.tags.indexOf(filter) !== -1;
      });
    },
  },
  mounted() {
    this.getCategories();
    this.getImages();
  },
};
</script>

<style src="./Portfolio.scss" scoped lang="scss"></style>
<style lang="css" scoped>
.list-complete {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
}
.list-complete-item {
  transition: transform 1s;
  flex: 1 1 25%;
  height: 200px;
  padding: 10px;
  /*   display: inline-block;
  margin-right: 10px; */
}
.list-complete-img {
  object-fit: cover;
  height: 100%;
  width: 200px;
}
.list-complete-enter, .list-complete-leave-to
/* .list-complete-leave-active for <2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.list-complete-leave-active {
  position: absolute;
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
</style>
