import Vue from 'vue';
import Router from 'vue-router';

import Layout from '@/components/Layout/Layout';

// Pages
import Dashboard from '@/pages/Dashboard/Dashboard';
import Portfolio from "@/pages/Portfolio/Portfolio";
import AddPortfolio from "@/pages/AddPortfolio/AddPortfolio";
import PortfolioCategories from "@/pages/PortfolioCategories/PortfolioCategories";
import Error from "@/pages/Error/Error";
import Login from "@/pages/Login/Login";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/",
      redirect: "login",
      name: "Layout",
      component: Layout,
      children: [
        {
          path: "dashboard",
          name: "Dashboard",
          component: Dashboard,
        },
        {
          path: "portfolio_categories",
          name: "portfolio_categories",
          component: PortfolioCategories,
        },
        {
          path: "portfolio",
          name: "portfolio",
          component: Portfolio,
        },
        {
          path: "add_portfolio",
          name: "add_portfolio",
          component: AddPortfolio,
        },
      ],
    },
    {
      path: "*",
      name: "Error",
      component: Error,
    },
  ],
});
