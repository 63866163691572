<template>
  <v-container fluid>
    <v-row no-gutters class="d-flex justify-space-between mt-10 mb-1">
      <h1 class="page-title mb-1">Portfolio Categories</h1>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="showAddCategoryForm = true"
            color="secondary"
            class="text-capitalize button-shadow mr-1"
            >Add</v-btn
          >
        </template>
      </v-menu></v-row
    >
    <div class="tables-basic">
      <v-row>
        <v-col cols="12">
          <v-card class="employee-list mb-1">
            <v-card-title class="pa-6 pb-3">
              <p>Categories</p>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="categories_table.search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="categories_table.headers"
              :items="categories_table.categories"
              :search="categories_table.search"
              item-key="name"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="category in items" :key="category.name">
                    <td>{{ category.name }}</td>
                    <td>{{ category.type | formatType }}</td>
                    <td>
                      <v-icon
                        @click="
                          showDeleteDialog = true;
                          toDelete = category.id;
                        "
                      >
                        mdi-delete
                      </v-icon>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-row justify="center">
      <v-dialog v-model="showAddCategoryForm" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Add Category</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="8">
                  <v-text-field
                    label="Name"
                    v-model="form.name"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="8">
                  <v-select
                    :items="types"
                    item-text="name"
                    item-value="id"
                    label="Type"
                    v-model="form.type"
                    required
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="showAddCategoryForm = false"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              :loading="form.busy"
              text
              @click="addPortfolioCategory"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showDeleteDialog" max-width="500px">
        <v-card>
          <v-card-title class="text-h5"
            >Are you sure you want to delete this item?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="
                showDeleteDialog = false;
                toDelete = '';
              "
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" :loading="deleteLoading" text @click="deletePortfolioCategory()"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import Form from "vform";

export default {
  name: "Tables",
  data() {
    return {
      categories_table: {
        categories: [],
        search: "",
        headers: [
          {
            text: "Name",
            align: "start",
            sortable: true,
            value: "name",
          },
          { text: "Type", value: "type" },
          { text: "Actions", value: "actions", sortable: false },
        ],
      },
      showAddCategoryForm: false,
      showDeleteDialog: false,
      deleteLoading: false,
      toDelete: "",
      form: new Form({
        name: "",
        type: "",
      }),
      types: [
        {
          name: "Image",
          id: 1,
        },
        {
          name: "Video",
          id: 2,
        },
        {
          name: "Website",
          id: 3,
        },
      ],
    };
  },
  methods: {
    getCategories() {
      axios.get("portfolio-categories").then(({ data }) => {
        this.categories_table.categories = data;
      });
    },
    addPortfolioCategory() {
      this.form
        .submit("post", "portfolio-categories")
        .then(() => {
          this.showAddCategoryForm = false;
          this.getCategories();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deletePortfolioCategory() {
      this.deleteLoading = true;
      axios
        .delete("portfolio-categories/" + this.toDelete)
        .then(() => {
          this.getCategories();
          this.showDeleteDialog = false;
          this.deleteLoading = false;
        })
        .catch(() => {
          this.showDeleteDialog = true;
          this.deleteLoading = false;
        });
    },
  },
  filters: {
    formatType: function(value) {
      var type;
      switch (value) {
        case 1:
          type = "Image";
          break;
        case 2:
          type = "Video";
          break;

        default:
          type = "Website";
          break;
      }
      return type;
    },
  },
  mounted() {
    this.getCategories();
  },
};
</script>

<style src="./PortfolioCategories.scss" lang="scss"></style>
